// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
};

// ==============================|| menus MENU ITEMS ||============================== //
// const menus = {
//     id: 'application',
//     title: <FormattedMessage id="application" />,
//     icon: icons.IconApps,
//     type: 'group',
//     children: [
//         {
//             id: 'Master Setup',
//             title: <FormattedMessage id="Master Setup" />,
//             type: 'collapse',
//             icon: icons.IconUserCheck,
//             children: [
//                 {
//                     id: 'Program',
//                     title: <FormattedMessage id="Program" />,
//                     type: 'item',
//                     url: '/program/list'
//                 },
//                 {
//                     id: 'Batch',
//                     title: <FormattedMessage id="Batch" />,
//                     type: 'item',
//                     url: '/batch/list'
//                 },
//                 {
//                     id: 'Branch',
//                     title: <FormattedMessage id="Branch" />,
//                     type: 'item',
//                     url: '/branch/list'
//                 },
//             ]
//         },
//     ]
// };

const menus = {
  id: "application",
  // title: <FormattedMessage id="application" />,
  icon: icons.IconApps,
  type: "group",
  children: [],
};

const checkInstitutionType =
  localStorage.getItem("institutionType") === "School";

const SuperAdminMenu = () => {
  const MenusData = [
    {
      id: "Dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/d66335a1-cbbe-4b71-926f-fe35888ab980",
      url: "/dashboard/analytics",
      breadcrumbs: false,
      target: false,
      children: [],
    },
    {
      id: "Institute",
      title: <FormattedMessage id="Institute Registration" />,
      type: "item",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/fe75398f-bd45-41d8-b26f-0c31ee20f9d0",
      url: "/institute",
      breadcrumbs: false,
      target: false,
      children: [],
    },
    {
      id: "Menu Setup",
      title: <FormattedMessage id="Menu Setup" />,
      type: "collapse",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/e74e2b66-32df-4edf-a15f-bd512dae7ec0",
      children: [
        {
          id: "Module",
          title: <FormattedMessage id="Module" />,
          type: "item",
          url: "/module/list",
        },
        {
          id: "Menu Permission",
          title: <FormattedMessage id="Menu Permission" />,
          type: "item",
          url: "/menu/permission/list",
        },
      ],
    },
  ];
  menus.children = MenusData;
};

const StaticMenus = () => {
  const MenusData = [
    {
      id: "Dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/d66335a1-cbbe-4b71-926f-fe35888ab980",
      url: "/institute/dashboard",
      breadcrumbs: false,
      target: false,
      children: [],
    },

    {
      id: "Alumni",
      title: <FormattedMessage id="Alumni" />,
      type: "collapse",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/fe75398f-bd45-41d8-b26f-0c31ee20f9d0",
      breadcrumbs: false,
      children: [
        {
          id: "PendingApplication",
          title: <FormattedMessage id="Pending Applications" />,
          type: "item",
          url: "/pending/application",
        },
        {
          id: "PendingSocialPost",
          title: <FormattedMessage id="Pending Post" />,
          type: "item",
          url: "/pending/post",
        },
        {
          id: "AlumniDirectory",
          title: <FormattedMessage id="Alumni Directory" />,
          type: "item",
          url: "/alumni/register",
        },
        {
          id: "AlumniGroup",
          title: <FormattedMessage id="Alumni Group" />,
          type: "item",
          url: "/groups",
        },
      ],
    },
    {
      id: "Event",
      title: <FormattedMessage id="Event" />,
      type: "collapse",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/3afad173-cc9d-420d-b982-742d67f356a9",
      children: [
        {
          id: "Event List",
          title: <FormattedMessage id="Event List" />,
          type: "item",
          url: "/createvent",
        },
        {
          id: "Event Approvals",
          title: <FormattedMessage id="Event Approval" />,
          type: "item",
          url: "/approvals",
        },
        {
          id: "EventType",
          title: <FormattedMessage id="Event Type" />,
          type: "item",
          url: "/eventType/list",
        },
      ],
    },
    {
      id: "Users",
      title: <FormattedMessage id="Users" />,
      type: "item",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/c92ff768-764d-4239-9fef-2085dfbccd13",
      url: "/user/list",
      breadcrumbs: false,
      target: false,
      children: [],
    },

    {
      id: "Master Setup",
      title: <FormattedMessage id="Master Setup" />,
      type: "collapse",
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/e74e2b66-32df-4edf-a15f-bd512dae7ec0",
      children: [
        {
          id: checkInstitutionType ? "Standard" : "Program",
          title: (
            <FormattedMessage
              id={checkInstitutionType ? "Standard" : "Program"}
            />
          ),
          type: "item",
          url: "/program/list",
        },
        {
          id: "Batch",
          title: <FormattedMessage id="Batch" />,
          type: "item",
          url: "/batch/list",
        },
        // {
        //   id: "Branch",
        //   title: <FormattedMessage id="Branch" />,
        //   type: "item",
        //   url: "/branch/list",
        // },
      ],
    },

    {
      id: "Settings",
      title: <FormattedMessage id="Settings" />,
      type: "item",
      // https://github.com/chhavientrar/imageslink/assets/124863903/2bb7b10d-0770-473d-bb7c-1f31f9da25de
      icon: "https://github.com/chhavientrar/imageslink/assets/124863903/a780d319-d22c-4b9c-b732-4a49e0795d11",
      url: "/setting",
      breadcrumbs: false,
      target: false,
      children: [],
    },
  ];
  menus.children = MenusData;
};
const AlumniMenus = () => {
  // const MenusData = [
  //   {
  //     id: "Dashboard",
  //     title: <FormattedMessage id="Dashboard" />,
  //     type: "item",
  //     // icon: icons.IconUserCheck,
  //     url: "/alumni/profile",
  //     breadcrumbs: false,
  //     target: false,
  //     children: [],
  //   },
  //   {
  //     id: "Batch",
  //     title: <FormattedMessage id="Batch" />,
  //     type: "item",
  //     // icon: icons.IconUserCheck,
  //     url: "/alumnidetails/batch/list",
  //     breadcrumbs: false,
  //     target: false,
  //     children: [],
  //   },
  //   {
  //     id: "Social",
  //     title: <FormattedMessage id="Social" />,
  //     type: "item",
  //     // icon: icons.IconUserCheck,
  //     url: "/socialprofile",
  //     breadcrumbs: false,
  //     target: false,
  //     children: [],
  //   },
  //   // {
  //   //   id: "social",
  //   //   title: <FormattedMessage id="Social" />,
  //   //   type: "collapse",
  //   //   children: [
  //   //     {
  //   //       id: "Social Profile",
  //   //       title: <FormattedMessage id="Social Profile" />,
  //   //       type: "item",
  //   //       url: "/socialprofile",
  //   //     },
  //   //   ],
  //   // },
  // ];
  // menus.children = MenusData;
};

const role = localStorage.getItem("role");

if (role === "ROLE_SUPER_ADMIN") {
  SuperAdminMenu();
} else if (role === "ROLE_COLLEGE_ADMIN" || "ROLE_COLLEGE_ADMIN") {
  StaticMenus();
} else if (role === "ROLE_ALUMNI") {
  AlumniMenus();
} else {
  console.log("lkjhgfdsfghjk");
}

export default menus;
