import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";

import moment from "moment";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { EditOutlined, InfoOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";

const EventCardList = ({
  post,
  type = "All",
  setPosts,
  getEventDetails,
  handleViewParticipants,
  handleEditNavigation,
}) => {
  const formatDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    const formattedDateTime = moment(dateTimeString).format(
      "DD MMMM - YYYY / hh:mm A"
    );
    return formattedDateTime;
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [joinAsVolunteer, setJoinAsVolunteer] = useState(false);
  const [joinAsParticipant, setJoinAsParticipant] = useState(false);

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const chipsToShow = showAll
    ? post?.eventForDetails
    : post?.eventForDetails?.slice(0, 2);

  const Tag = styled("div")(({ theme }) => ({
    backgroundColor: "#FFA000", // Background color similar to the image
    color: "white", // Text color
    height: "24px",
    fontWeight: "bold",
    paddingLeft: "16px",
    paddingBottom: "4px",
    paddingRight: "16px",
    clipPath: "polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 0%)", // Trapezoid shape
    marginLeft: "-8px",
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };
  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleJoinAsParticipant = () => {
    const volunteers = joinAsVolunteer;
    const participants = joinAsParticipant;

    axios
      .get(
        `/v1/event/register?volunteers=${volunteers}&Participants=${participants}&eventId=${
          post?.id
        }&alumniId=${localStorage.getItem("alumniId")}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "multipart/form-data",
            requestId: ConvertToRequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999) + rendomRequestId
            ),
          },
        }
      )
      .then((response) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "SuccessFully! Joined",
            variant: "alert",
            alert: {
              color: "success",
            },
            close: false,
          })
        );
        // getEventDetails();
        setPosts(response?.data?.data);
        handleClose();
        generateTimeStamp();
      })
      .catch((error) => {
        generateTimeStamp();
        dispatch(
          openSnackbar({
            open: true,
            message: error.response.data.Msg,
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      });
  };

  const theme = useTheme();
  const date = moment(post?.startDate);
  const month = date.format("MMM");
  const day = date.format("DD");
  return (
    <>
      <div>
        <Card
          sx={{
            maxWidth: 460,
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
          variant="outlined"
        >
          <CardMedia
            component="img"
            height="194"
            image={`data:image/*;base64, ${post?.binaryImage?.data}`}
            alt="Paella dish"
          />

          <CardActions disableSpacing sx={{ mt: -2, mb: -2, mr: -3 }}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              style={{ marginRight: "auto" }}
            >
              {formatDateTime(post.startDate, post.startTime)}
            </Typography>
            <IconButton>
              <EditOutlined onClick={() => handleEditNavigation(post?.id)} />
            </IconButton>

            <IconButton onClick={() => handleOpenInfo()} aria-label="info">
              <InfoOutlined color="secondary" />
            </IconButton>
            <Tag sx={{ ml: 1 }}>{post?.approvalStatus}</Tag>
          </CardActions>
          <CardContent sx={{ mt: -4 }}>
            <Grid container>
              <Grid item md={10} xs={12}>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleViewParticipants(post?.id)}
                  variant="h3"
                >
                  {/* {post?.eventCategories?.name} */}
                  {post?.title}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {post?.eventCategories === null
                    ? post?.categoryName
                    : post?.eventCategories?.name}{" "}
                  ({post?.eventType})
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <a href={post?.eventLink ?? ""} target="blank">
                    {" "}
                    {post?.eventSpace ?? ""}
                  </a>
                </Typography>
              </Grid>
              <Grid item md={2} xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70px",
                    width: "70px",
                    backgroundColor: "#2377dd",
                    borderRadius: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
                    textAlign: "center",
                    color: "#fafafa",
                  }}
                >
                  <h4 style={{ margin: 0 }}>{month.toUpperCase()}</h4>
                  <h1 style={{ margin: 0 }}>{day}</h1>
                </div>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: 1,
                marginTop: "15px",
              }}
            >
              {chipsToShow?.map((data, index) => (
                <Chip key={index} size="small" label={data?.name} />
              ))}
              {post?.eventForDetails?.length > 2 && (
                <IconButton
                  size="small"
                  onClick={handleShowMore}
                  sx={{ padding: 0 }}
                >
                  {showAll ? (
                    <FirstPageIcon sx={{ color: "primary" }} />
                  ) : (
                    <LastPageIcon />
                  )}
                </IconButton>
              )}
            </Box>
          </CardContent>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to join as a{" "}
            {joinAsVolunteer ? "volunteer" : "participant"}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleJoinAsParticipant();
              handleClose();
            }}
            color="primary"
          >
            Join
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog sx={{ p: 0 }} open={openInfo} onClose={handleCloseInfo}>
        <DialogTitle sx={{ p: 0 }}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: "flex", p: 1, justifyContent: "space-between" }}
          >
            <Typography sx={{ ml: 1, fontWeight: 500, fontSize: "22px" }}>
              Instruction
            </Typography>
            <IconButton onClick={() => handleCloseInfo()}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          {stripHtmlTags(post?.instruction)}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EventCardList;
