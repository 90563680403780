import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const AlumniDetailByBatch = Loadable(
  lazy(() => import("views/alumniSide/batch/index"))
);
const AlumniSocialProfile = Loadable(
  lazy(() => import("views/alumniSide/socialmedia/index"))
);
const AlumniEventManage = Loadable(
  lazy(() => import("views/application/users/social-profile/mypost/ManagePost"))
);
// ==============================|| AUTH ROUTING ||============================== //

const AlumniRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/alumnidetails/batch/list",
      element: <AlumniDetailByBatch />,
    },
    {
      path: "/socialprofile",
      element: <AlumniSocialProfile />,
    },
    {
      path: "/eventdetails",
      element: <AlumniEventManage />,
    },
  ],
};

export default AlumniRoutes;
