import {
  Avatar,
  Button,
  Collapse,
  Grid,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import { useTheme } from "@emotion/react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import AnimatedLoader from "reuseableTable/AnimatedLoader";
import moment from "moment";
import ViewUploadedImages from "views/application/users/social-profile/ViewUploadedImages";
import LikeList from "views/application/users/social-profile/LikeList";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";
import CommentList from "views/application/users/social-profile/CommentList";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const likeArray = [
  {
    type: "Like",
    emoji: "👍",
  },
  {
    type: "Celebrate",
    emoji: "👏",
  },
  {
    type: "Funny",
    emoji: "😄",
  },
  {
    type: "Love",
    emoji: "❤️",
  },
];

const PostCard = ({ post, setPosts, alumniDetails, isLoading }) => {
  const [openLikeList, setOpenLikeList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [commentList, setCommentList] = useState([]);
  const [openComment, setOpenComment] = useState(false);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenLikeList = () => {
    setOpenLikeList(true);
  };

  const handleCloseLikeList = () => {
    setOpenLikeList(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  const handleChangeComment = () => {
    setOpenComment((prev) => !prev);
    if (!openComment) {
      getCommentList(post?.id);
    }
  };

  const getCommentList = async (id) => {
    try {
      const response = await axios.get(
        `/v1/posts/list/comment?postId=${id}&pageSize=200&pageNumber=0`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      setCommentList(response?.data?.data?.content);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  let commentsResult = <></>;

  if (commentList) {
    commentsResult = commentList.map((comment) => (
      <CommentList
        setPosts={setPosts}
        postId={post?.id}
        comment={comment}
        key={comment.id}
        getCommentList={getCommentList}
        type="FromAdmin"
      />
    ));
  }

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <MainCard sx={{ mt: 1 }}>
      {isLoading ? (
        <>
          <AnimatedLoader />
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar
                    alt={post?.alumniMemberMini?.name}
                    src={`data:image/*;base64, ${post?.alumniMemberMini?.avatar?.data}`}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography align="left" variant="h6" component="div">
                    {post?.alumniMemberMini?.name}
                  </Typography>
                  <Typography
                    align="left"
                    variant="caption"
                    color="textSecondary"
                  >
                    Batch {post?.alumniMemberMini?.batchTag} |{" "}
                    {post?.alumniMemberMini?.department}
                  </Typography>
                  <Typography
                    align="left"
                    variant="caption"
                    color="textSecondary"
                  >
                    {moment(post.createdAt).format("DD MMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <Grid
                item
                xs={12}
                sx={{
                  "& > p": {
                    ...theme.typography.body1,
                    mb: 0,
                  },
                }}
              >
                <ReactMarkdown remarkPlugins={[gfm]}>
                  {post.postText}
                </ReactMarkdown>
              </Grid>

              {post.images && post.images.length > 0 && (
                <Grid item xs={12}>
                  <ViewUploadedImages
                    postId={post?.id}
                    itemData={post?.images}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    mt: 0,
                    color:
                      theme.palette.mode === "dark" ? "grey.700" : "grey.800",
                  }}
                >
                  <Grid item>
                    {post?.reactionCount && (
                      <div
                        style={{
                          width: "100px",
                        }}
                      >
                        {Object.entries(post?.reactionCount).map(
                          ([key, value]) => {
                            const emojiItem = likeArray.find(
                              (item) => item.type === key
                            );
                            return (
                              <span
                                key={key}
                                style={{
                                  cursor: "pointer",
                                  borderBottom: "1px solid transparent",
                                  transition: "border-color 0.3s ease",
                                }}
                                onClick={handleOpenLikeList}
                                onMouseEnter={(e) => {
                                  e.target.style.borderBottom =
                                    "1px solid black";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.borderBottom =
                                    "1px solid transparent";
                                }}
                              >
                                {emojiItem?.emoji}
                              </span>
                            );
                          }
                        )}
                      </div>
                    )}

                    <Stack direction="row" spacing={2}>
                      <Button variant="text" color="inherit" size="small">
                        {post?.likes}
                        <Typography
                          color="inherit"
                          sx={{
                            fontWeight: 500,
                            ml: 0.5,
                            display: { xs: "none", sm: "block" },
                          }}
                        >
                          likes
                        </Typography>
                      </Button>
                      {!post?.postLikesIds?.includes(
                        localStorage.getItem("userId")
                      ) && (
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onMouseLeave={handlePopoverClose}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          disableRestoreFocus
                        >
                          <Stack direction="row" spacing={1} p={1}>
                            {likeArray?.map((like, index) => (
                              <Typography
                                key={index}
                                sx={{
                                  cursor: "pointer",
                                  fontWeight: isHovered ? 500 : 400,
                                }}
                                variant={isHovered ? "h1" : "h3"}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                // onClick={() =>
                                //   handlePostLikes(
                                //     post?.id,
                                //     like?.type,
                                //     !post?.postLikesIds?.includes(
                                //       localStorage.getItem("userId")
                                //     )
                                //       ? true
                                //       : false
                                //   )
                                // }
                              >
                                {like.emoji}
                              </Typography>
                            ))}
                          </Stack>
                        </Popover>
                      )}

                      <Button
                        onClick={handleChangeComment}
                        size="small"
                        variant="text"
                        color="inherit"
                      >
                        {post?.comments} comments
                      </Button>
                    </Stack>
                  </Grid>

                  {openLikeList && (
                    <LikeList
                      post={post}
                      postId={post?.id}
                      open={openLikeList}
                      handleClose={handleCloseLikeList}
                    />
                  )}

                  <Grid item xs={12}>
                    <Collapse in={openComment}>{commentsResult}</Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </MainCard>
  );
};

export default PostCard;
