import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const CreateEvent = Loadable(lazy(() => import("views/createEvent/index")));
const EditEvent = Loadable(lazy(() => import("views/createEvent/EditEvent")));
const JoinedEvents = Loadable(lazy(() => import("views/joinedEvents")));
const EventApproval = Loadable(
  lazy(() => import("views/eventApprovals/index"))
);
// ==============================|| AUTH ROUTING ||============================== //

const EventRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/createvent",
      element: <CreateEvent />,
    },
    {
      path: "/edit/event/:id",
      element: <EditEvent />,
    },
    {
      path: "/joined/:id",
      element: <JoinedEvents />,
    },
    {
      path: "/approvals",
      element: <EventApproval />,
    },
  ],
};

export default EventRoutes;
