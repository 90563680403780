import PropTypes from "prop-types";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import TotalIncomeCard from "ui-component/cards/Skeleton/TotalIncomeCard";

// assets
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

import totalAlumni from "assets/images/totalAlumni.png";
import { useNavigate } from "react-router-dom";
// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const TotalAlumniCountCard = ({ isLoading, count }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/alumni/register`);
  };

  return (
    <>
      {isLoading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      background: "#D4A9F2 0% 0% no-repeat padding-box",
                      borderRadius: " 12px",
                      opacity: 1,
                      p: 2,
                    }}
                  >
                    {/* <StorefrontTwoToneIcon fontSize="inherit" /> */}
                    <img
                      src={totalAlumni}
                      height="25px"
                      width="25px"
                      alt="alumni"
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    py: 0,
                    mt: 0.45,
                    mb: 0.45,
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavigate()}
                  primary={<Typography variant="h3">{count}</Typography>}
                  secondary={
                    <Typography
                      variant="h6"
                      sx={{
                        // color: theme.palette.grey[500],
                        mt: 0.5,
                      }}
                    >
                      Total Alumni Directory
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

TotalAlumniCountCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default TotalAlumniCountCard;
