import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const ProgramList = Loadable(
  lazy(() => import("views/mastersetup/progaram/Main"))
);
const BatchList = Loadable(lazy(() => import("views/mastersetup/batch/Main")));
const BranchList = Loadable(
  lazy(() => import("views/mastersetup/branch/Main"))
);
const UserList = Loadable(lazy(() => import("views/createUser/index")));
const EventType = Loadable(lazy(() => import("views/mastersetup/eventType/index")));

// ==============================|| AUTH ROUTING ||============================== //

const MasterRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/program/list",
      element: <ProgramList />,
    },
    {
      path: "/batch/list",
      element: <BatchList />,
    },
    {
      path: "/branch/list",
      element: <BranchList />,
    },
    {
      path: "/user/list",
      element: <UserList />,
    },
    {
      path: "/eventType/list",
      element: <EventType />,
    },
  ],
};

export default MasterRoutes;
