// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

// project-import
import MainCard from "ui-component/cards/MainCard";
import MapContainerStyled from "ui-component/third-party/map/MapContainerStyled";

import ClustersMap from "./maps/clusters-map";

const MAPBOX_THEMES = {
  light: "mapbox://styles/mapbox/light-v10",
  dark: "mapbox://styles/mapbox/dark-v10",
  streets: "mapbox://styles/mapbox/streets-v11",
  outdoors: "mapbox://styles/mapbox/outdoors-v11",
  satellite: "mapbox://styles/mapbox/satellite-v9",
  satelliteStreets: "mapbox://styles/mapbox/satellite-streets-v11",
};

const mapConfiguration = {
  mapboxAccessToken:
    "pk.eyJ1IjoiY2hoYXZpMTIzIiwiYSI6ImNsdTJmZDhybTBrZ3IyaXBkeWt4MXBkZ28ifQ.U-05vz8qIkbuMVG0EXxROQ",
  minZoom: 1,
};

// ==============================|| MAP ||============================== //

const Map = () => {
  const theme = useTheme();

  return (
    <Grid item xs={12} md={12}>
      <MapContainerStyled>
        <ClustersMap
          {...mapConfiguration}
          mapStyle={
            theme.palette.mode === "dark"
              ? MAPBOX_THEMES.dark
              : MAPBOX_THEMES.light
          }
        />
      </MapContainerStyled>
    </Grid>
  );
};

export default Map;
