import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const Settings = Loadable(lazy(() => import("views/settings/Settings")));

// ==============================|| AUTH ROUTING ||============================== //

const SettingRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/setting",
      element: <Settings />,
    },
  ],
};

export default SettingRoutes;
