import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const LoginDetails = Loadable(
  lazy(() => import("views/report/LoginDetails/index"))
);
const Maps = Loadable(lazy(() => import("views/application/map")));
const Deatils = Loadable(
  lazy(() => import("views/dashboard/instituteDashboard/ListByType"))
);
const GroupDetails = Loadable(lazy(() => import("views/group/index")));
const EditGroup = Loadable(lazy(() => import("views/group/EditGroupDetails")));

// ==============================|| AUTH ROUTING ||============================== //

const ReportRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/login/details",
      element: <LoginDetails />,
    },
    {
      path: "/map",
      element: <Maps />,
    },
    {
      path: "/alumni/deatils/:name/:type",
      element: <Deatils />,
    },
    {
      path: "/groups",
      element: <GroupDetails />,
    },
    {
      path: "/groupdetails/edit/:id",
      element: <EditGroup />,
    },
  ],
};

export default ReportRoutes;
