import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Avatar, Link } from "@mui/material";

// project imports
import { DASHBOARD_PATH } from "config";
import Logo from "ui-component/Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <Link component={RouterLink} to={DASHBOARD_PATH}>
    <img
      src="/assets/images/icons/Entrarlogo.svg"
      alt="logo"
      width="100"
      height="32"
    />
  </Link>
);

export default LogoSection;
