import { Avatar, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";

const SearchAlumni = ({ liveUpdateList }) => {
  return (
    <div>
      {liveUpdateList.length > 0 ? (
        <>
          {liveUpdateList?.map((data) => (
            <Box key={data.id} mb={2}>
              <Box display="flex" alignItems="center">
                <Avatar
                  variant="rounded"
                  alt={data?.alumniMemberMini?.name}
                  sx={{
                    borderRadius: "12px",
                    opacity: 1,
                  }}
                  src={`data:image/*;base64, ${data?.alumniMemberMini?.avatar?.data}`}
                />
                <Box ml={1}>
                  <Typography variant="body1" fontWeight="bold">
                    {data?.alumniMemberMini?.name.toUpperCase()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    updated {data?.updatesType} info{" "}
                    {moment(data.updatedAt).fromNow()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            textAlign: "center",
            border: "1px solid #f6f6f6",
            p: 5,
          }}
        >
          <Typography>No recent updates from alumni.</Typography>
        </Grid>
      )}
    </div>
  );
};

export default SearchAlumni;
