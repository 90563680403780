import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Grid, Dialog, DialogContent } from "@mui/material";
import { ImageListItem, ImageListItemBar } from "@mui/material";
import MImageList from "@mui/material/ImageList";
import useConfig from "hooks/useConfig";
import Carousel, { Modal, ModalGateway } from "react-images";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";

const ViewUploadedImages = ({ itemData, postId }) => {
  const { borderRadius } = useConfig();
  const [clickedImages, setClickedImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const chunkedData = chunkArray(itemData, 5);

  const handleImageClick = (index) => {
    getPresignedUrl(index);
  };
  const getPresignedUrl = async (index) => {
    try {
      const response = await axios.get(`/v1/posts/files?postId=${postId}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      const formattedImages = response?.data?.data?.presignedUrl?.map(
        (item) => ({
          src: item,
          caption: "caption",
        })
      );
      setClickedImages(formattedImages);
      setCurrentImageIndex(index);
      setOpenModal(true);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    generateTimeStamp();
  }, []);

  return (
    <Grid container spacing={2}>
      {chunkedData.map((row, rowIndex) => (
        <Grid item xs={12} md={12} key={rowIndex}>
          <MImageList
            cols={row?.length === 1 ? 3 : 3}
            gap={8}
            sx={{
              transform: "translateZ(0)",
              overflowY: "visible",
              mb: 0,
            }}
          >
            {row.map((item, index) => {
              if (!item.data) return null;
              return (
                <ImageListItem
                  key={index}
                  sx={{
                    overflow: "hidden",
                    borderRadius: `${borderRadius}px`,
                    cursor: "pointer",
                  }}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={`data:image/*;base64, ${item.data}`}
                    alt={item.title}
                    loading="lazy"
                    height={150}
                  />
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    title={item.title}
                    position="top"
                    actionPosition="left"
                  />
                </ImageListItem>
              );
            })}
          </MImageList>
        </Grid>
      ))}

      {/* <Dialog open={openModal} onClose={handleCloseModal} sx={{ padding: "0" }}>
        <DialogContent sx={{ padding: "0" }}>
          <Carousel
            views={clickedImages}
            currentIndex={currentImageIndex}
            onClose={handleCloseModal}
          />
        </DialogContent>
      </Dialog> */}

     
      <ModalGateway>
        {openModal ? (
          <Modal onClose={handleCloseModal}>
            <Carousel
              views={clickedImages}
              currentIndex={currentImageIndex}
              onClose={handleCloseModal}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Grid>
  );
};

ViewUploadedImages.propTypes = {
  itemData: PropTypes.array.isRequired,
};

export default ViewUploadedImages;
