import { useState } from "react";

function useRequestIdGenerater() {
  const [base64Number, setBase64Number] = useState("");

  const generateBase64 = (email, username) => {
    const combinedString = `${email}${username}}`;
    const encodedString = btoa(combinedString);
    setBase64Number(encodedString);
    return encodedString;
  };

  return generateBase64;
}

export default useRequestIdGenerater;
