import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const MapContainerStyled = styled(Box)(({ theme }) => ({
  height: "100%", // Set the height to fill the parent container
  overflow: "hidden",
  padding: theme.spacing(2), // Use theme spacing for consistent padding
  borderRadius: theme.spacing(1), // Use theme spacing for consistent borderRadius
  position: "relative", // Ensure proper positioning within the Grid item
  "& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right": {
    display: "none",
  },
}));

export default MapContainerStyled;
