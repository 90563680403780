import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import TotalAlumniCountCard from "./TotalAlumniCountCard";
import AlumniStatsCard from "./AlumniStateCard";
import ActiveAlumni from "assets/images/activeAlumni.png";
import SubCard from "ui-component/cards/SubCard";
import SearchAlumni from "./SearchAlumni";
import EventCard from "./EventCard";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import axios from "axios";
import PostCard from "./PostDetailsCard";
import TotalPendingMembers from "./TotalPendingMembers";
import TableView from "../instituteDashboard/TableView";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaddingIcon from "@mui/icons-material/Padding";
import EventCardList from "views/createEvent/EventCardList";
// import Map from "react-map-gl/dist/esm/components/map";
import Map from "views/application/map";

const NewDashboard = () => {
  const [allPostList, setAllPostList] = useState([]);
  const [getlastdata, setLastdata] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [totalAlumniCount, setTotalAlumniCount] = useState(0);
  const [totalPendingCount, setTotalPendingCount] = useState(0);
  const [countByOragnization, setCountByOragnization] = useState([]);
  const [countByDesigination, setCountByDesigination] = useState([]);

  const [upcomingEvent, setUpComingEvent] = useState([]);

  const pageSize = 3;

  const [liveUpdateList, setLiveUpdateList] = useState([]);

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const transformDataWithNameKey = (data, keyName) => {
    return data.map((item) => ({
      name: item[keyName],
      count: item.count,
    }));
  };

  const getDashboardData = async () => {
    try {
      const response = await axios.get(`/v1/dashboard/count`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setTotalAlumniCount(response?.data?.data?.totalAlumniCount);
      setTotalPendingCount(response?.data?.data?.pendingMemberCount);

      const organizationtransformedData = transformDataWithNameKey(
        response?.data?.data?.alumniCountByOrganizations,
        "organizationName"
      );
      setCountByOragnization(organizationtransformedData);

      const DesignationtransformedData = transformDataWithNameKey(
        response?.data?.data?.alumniCountByDesignations,
        "designationName"
      );
      setCountByDesigination(DesignationtransformedData);

      generateTimeStamp();
    } catch (err) {
      generateTimeStamp();
      console.log(err);
    }
  };

  const getPostDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/v1/posts/all?pageSize=10&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );

      setAllPostList((prevPosts) => [
        ...prevPosts,
        ...response?.data?.data?.content,
      ]);
      setLastdata(response?.data?.data?.last);
      generateTimeStamp();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      generateTimeStamp();
      console.log(err);
    }
  };

  const getLiveProfileUpdateData = async () => {
    try {
      const response = await axios.get(`/v1/dashboard/alumni-live-updates`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      setLiveUpdateList(response?.data?.data);
      generateTimeStamp();
    } catch (err) {
      generateTimeStamp();
      console.log(err);
    }
  };

  const getEventList = async () => {
    try {
      const response = await axios.get(`/v1/event/institute/upcoming/event`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      setUpComingEvent(response?.data?.data);
      generateTimeStamp();
    } catch (err) {
      generateTimeStamp();
      console.log(err);
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!getlastdata) {
      getPostDetails();
    }

    generateTimeStamp();
  }, [pageNumber]);

  useEffect(() => {
    getLiveProfileUpdateData();

    getDashboardData();
    getEventList();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={3} lg={3} xs={12}>
          <TotalAlumniCountCard count={totalAlumniCount} />
          <Grid container sx={{ mt: 1 }} spacing={1}>
            <Grid item md={12} xs={12} lg={12}>
              {/* <AlumniStatsCard
                count={13552}
                label="Total Alumni"
                color="#2464A4"
                icon={<img src={totalAlumni1} alt="total Alumni" />}
              /> */}
              <TotalPendingMembers color="#3AC33D" count={totalPendingCount} />
            </Grid>
            {/* <Grid item md={6} xs={6} lg={6}>
              <AlumniStatsCard
                count={totalPendingCount}
                color="#3AC33D"
                label="Pending Registrations"
                icon={<img src={ActiveAlumni} alt="total Alumni" />}
              />
            </Grid> */}
            <Grid item md={12} xs={12} lg={12}>
              {/* <AlumniStatsCard
                count={13552}
                color="#3AC33D"
                label="Active Alumni"
                icon={<img src={ActiveAlumni} alt="total Alumni" />}
              /> */}
              <TableView
                color="#d4a9f2"
                icon={<ApartmentIcon />}
                list={countByOragnization}
                titleName="Organization"
              />
            </Grid>
            <Grid item md={12} xs={12} lg={12}>
              <TableView
                color="#3ac33d"
                icon={<PaddingIcon />}
                list={countByDesigination}
                titleName="Designation"
              />
            </Grid>

            <Grid item md={12} xs={12} lg={12}>
              <SubCard
                sx={{ mt: 1 }}
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ textAlign: "center", fontWeight: 500 }}>
                        Live Update
                      </Typography>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          background: "red",
                          borderRadius: "50%",
                          marginLeft: "5px",
                          animation: "live 2s linear infinite",
                        }}
                      ></div>
                      <style jsx global>{`
                        @keyframes live {
                          0% {
                            transform: scale(1);
                          }
                          50% {
                            transform: scale(1.5);
                          }
                          100% {
                            transform: scale(1);
                          }
                        }
                      `}</style>
                    </div>
                  </div>
                }
              >
                <SearchAlumni liveUpdateList={liveUpdateList} />
              </SubCard>
            </Grid>

            {/* <Grid item md={6} xs={6} lg={6}>
              <AlumniStatsCard
                count={13552}
                color="#3AC33D"
                label="Active Alumni"
                icon={<img src={ActiveAlumni} alt="total Alumni" />}
              />
            </Grid>
            <Grid item md={6} xs={6} lg={6}>
              <AlumniStatsCard
                count={13552}
                color="#3AC33D"
                label="Active Alumni"
                icon={<img src={ActiveAlumni} alt="total Alumni" />}
              />
            </Grid> */}
          </Grid>
          {/* <SubCard title="Approvals" sx={{ p: 0, mt: 1 }}>
            <ApprovalCard />
            <ApprovalCard />
          </SubCard> */}
        </Grid>

        {/* <Grid item md={5} lg={5} xs={12}>
          <Card>
            <CardContent>
              <Typography sx={{ fontWeight: 600 }}>Posts</Typography>
            </CardContent>
          </Card>
          <Grid>
            {allPostList.length === 0 ? (
              <Card sx={{ mt: 1 }}>
                <CardContent>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #f6f6f6",
                      p: 5,
                    }}
                  >
                    <Typography>No Posts.</Typography>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              allPostList?.map((post) => (
                <Grid key={post.id} item xs={12}>
                  <PostCard
                    isLoading={isLoading}
                    // alumniDetails={alumniDetails}
                    post={post}
                    // setPosts={handleUpdatePostLikes}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid> */}

        <Grid item md={9} lg={9} xs={12}>
          <SubCard>
            <Map />
          </SubCard>

          <Card sx={{ mt: 1 }}>
            <CardContent>
              <Typography sx={{ fontWeight: 600 }}>Posts</Typography>
            </CardContent>
          </Card>
          <Grid>
            {allPostList.length === 0 ? (
              <Card sx={{ mt: 1 }}>
                <CardContent>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      textAlign: "center",
                      border: "1px solid #f6f6f6",
                      p: 5,
                    }}
                  >
                    <Typography>No Posts.</Typography>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              allPostList?.map((post) => (
                <Grid key={post.id} item xs={12}>
                  <PostCard
                    isLoading={isLoading}
                    // alumniDetails={alumniDetails}
                    post={post}
                    // setPosts={handleUpdatePostLikes}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>

        <Grid item md={4} lg={4} xs={12}>
          {/* <SubCard title="Events" sx={{ p: 0, mt: 1 }}>
            <EventCard />
          </SubCard> */}

          <Grid container p={2}>
            {upcomingEvent?.map((post) => (
              <EventCard
                // handleEditNavigation={handleEditNavigation}
                // handleViewParticipants={handleViewParticipants}
                // getEventDetails={fetchData}
                // setPost={setPageData}
                post={post}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewDashboard;
