import { lazy } from "react";
import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";
import MasterRoutes from "./MasterRoutes";
import RegistrationRoute from "./RegistrationRoute";
import MenuRoutes from "./MenuRoutes";
import ReportRoutes from "./ReportRoute";
import AlumniRoutes from "./AlumniRoutes";
import ConnectionRoute from "./ConnectionRoute";
import SettingRoutes from "./SettingRoutes";
import EventRoutes from "./EventRoutes";
import AdminRoutes from "./AdminRoute";

const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));
const RedirectLandingPage = Loadable(
  lazy(() => import("views/redirect/Redirect"))
);

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: "/", element: <PagesLanding /> },
    { path: "/redirect", element: <RedirectLandingPage /> },
    AuthenticationRoutes,
    LoginRoutes,
    RegistrationRoute,
    MainRoutes,
    MasterRoutes,
    MenuRoutes,
    ReportRoutes,
    AlumniRoutes,
    ConnectionRoute,
    SettingRoutes,
    EventRoutes,
    AdminRoutes,
  ]);
}
