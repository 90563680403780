import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Grid,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Popper,
  TextField,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import debounce from "lodash.debounce";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  })
);

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",

  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.secondary.light,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark,
  "&:hover": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.light
        : theme.palette.secondary.light,
  },
}));
const GroupItems = styled("ul")({
  padding: 0,
});

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="16px"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <HeaderAvatarStyle variant="rounded">
            <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
          </HeaderAvatarStyle>
          <Box sx={{ ml: 2 }}></Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const RequestId = useRequestIdGenerater();
  const [searchList, setSearchList] = useState([]);
  const [id, setId] = useState("");

  const inputDelay = 200;
  let timerId;

  const fetchSearchList = async (keyWord) => {
    if (!keyWord) {
      return;
    }

    try {
      const response = await axios.post(
        `/v1/alumni/global/search`,
        { searchKey: keyWord },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(Math.floor(1000 + Math.random() * 9999)),
          },
        }
      );
      const { data } = await response.data;

      setSearchList(data);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedFetchSearchList = debounce(fetchSearchList, 100);

  const handleInputChange = async (event, value) => {
    const inputValue = value || event.target.value;

    if (timerId) {
      clearTimeout(timerId);
    }

    if (inputValue.length > 2) {
      timerId = setTimeout(async () => {
        const data = await fetchSearchList(inputValue);
        setSearchList(data);
      }, inputDelay);
    } else {
      setSearchList([]);
    }
  };

  const fetchonSelect = (option) => {
    if (option !== null) {
      console.log(option, "OptionData");
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <HeaderAvatarStyle
                  variant="rounded"
                  {...bindToggle(popupState)}
                >
                  <IconSearch stroke={1.5} size="19px" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? theme.palette.dark[900]
                              : "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <Autocomplete
                                id="input-search-header"
                                options={searchList ?? []}
                                getOptionLabel={(option) => option?.name}
                                sx={{ width: 300 }}
                                filterOptions={(options) => options}
                                renderInput={(params) => {
                                  const { InputProps, ...other } = params;
                                  return (
                                    <OutlineInputStyle
                                      {...InputProps}
                                      {...other}
                                      placeholder="Search"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <IconSearch
                                            stroke={1.5}
                                            size="16px"
                                            color={theme.palette.grey[500]}
                                          />
                                        </InputAdornment>
                                      }
                                      theme={theme}
                                      onChange={handleInputChange}
                                    />
                                  );
                                }}
                                renderOption={(props, option) => (
                                  <li {...props} key={option?.id}>
                                    <Avatar
                                      src={`data:image/*;base64, ${option?.avatar?.data}`}
                                      alt={option.name}
                                      sx={{
                                        width: 34,
                                        height: 34,
                                        marginRight: 1,
                                      }}
                                    />
                                    {option.name} {option?.batch?.tag}
                                  </li>
                                )}
                                // onMouseUp={(e) => {
                                //   debouncedFetchSearchList(e.target.value);
                                // }}
                                onInputChange={(event, newInputValue) => {
                                  debouncedFetchSearchList(newInputValue);
                                }}
                                onSelect={(option) => {
                                  fetchonSelect(option);
                                }}
                                onChange={(e, v) => {
                                  if (v !== null) {
                                    const idWithType = v.idWithType;
                                    setId(idWithType);
                                  } else {
                                    setId("");
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Autocomplete
          disableCloseOnSelect
          id="input-search-header"
          options={searchList ?? []}
          getOptionLabel={(option) => option?.name}
          sx={{ width: 300 }}
          filterOptions={(options) => options}
          renderInput={(params) => {
            const { InputProps, ...other } = params;
            return (
              <OutlineInputStyle
                {...InputProps}
                {...other}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="16px"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                }
                theme={theme}
                onChange={handleInputChange}
              />
            );
          }}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              key={option?.id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ListItemAvatar>
                <Avatar
                  src={`data:image/*;base64, ${option?.avatar?.data}`}
                  alt={option.name}
                  sx={{ width: 34, height: 34, marginRight: 2 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${option.name} (${option?.batch?.tag})`}
              />
              {/* <ConnectWithoutContactIcon sx={{ marginLeft: "auto" }} /> */}
            </ListItem>
          )}
          // onMouseUp={(e) => {
          //   debouncedFetchSearchList(e.target.value);
          // }}
          onInputChange={(event, newInputValue) => {
            debouncedFetchSearchList(newInputValue);
          }}
          onSelect={(option) => {
            fetchonSelect(option);
          }}
          onChange={(e, v) => {
            if (v !== null) {
              const idWithType = v.idWithType;
              setId(idWithType);
            } else {
              setId("");
            }
          }}
        />
      </Box>
    </>
  );
};

export default SearchSection;
