import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Master routing
const CreateInstitute = Loadable(lazy(() => import("views/instituteRegistration/index")));

// ==============================|| AUTH ROUTING ||============================== //

const AdminRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/institute",
      element: <CreateInstitute />,
    },
  ],
};

export default AdminRoutes;
