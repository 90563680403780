import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, IconButton, TableBody, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TableView({ list, titleName, icon, color }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: color,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [showAll, setShowAll] = React.useState(false);
  const validData = list?.filter((data) => data?.name !== null) || [];
  const navigate = useNavigate();

  const handleNavigate = (name, type) => {
    navigate(`/alumni/deatils/${name}/${type}`);
  };

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <IconButton color="inherit">{icon}</IconButton>
              <span>{titleName}</span>
            </StyledTableCell>
            <StyledTableCell>
              {validData.length > 4 ? (
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={handleSeeMoreClick}
                >
                  {showAll ? "Show Less" : "See All"}
                </Typography>
              ) : (
                "Member"
              )}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {validData.length === 0 ? (
            <StyledTableRow>
              <StyledTableCell colSpan={2} align="center">
                No data.
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            validData.slice(0, showAll ? validData.length : 4).map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  style={{ cursor: "pointer" }}
                  component="th"
                  scope="row"
                  onClick={() => {
                    handleNavigate(row?.name, titleName);
                  }}
                >
                  {row?.name?.toUpperCase()}
                </StyledTableCell>
                <StyledTableCell>{row?.count}</StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
