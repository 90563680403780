import { useState } from "react";

const useTimeStamp = () => {
  const [rendomRequestId, setRendomRequestId] = useState(null);

  const generateTimeStamp = () => {
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();
    const newRequestId = `${Math.floor(
      1000 + Math.random() * 9999
    )} ${currentTimestamp}`;
    setRendomRequestId(newRequestId);
    return newRequestId;
  };

  return { rendomRequestId, generateTimeStamp };
};

export default useTimeStamp;
