import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';

// Master routing
const ModuleList = Loadable(lazy(() => import('views/menusetup/module/index')));
const MenusList = Loadable(lazy(() => import('views/menusetup/menu/index')));
const MenuPermissionList = Loadable(lazy(() => import('views/menusetup/menupermission/index')));
const MenuPermissionEdit = Loadable(lazy(() => import('views/menusetup/menupermission/EditMenuPermission')));
// ==============================|| AUTH ROUTING ||============================== //

const MenuRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/module/list',
            element: <ModuleList />
        },
        {
            path: '/menus/list/:id',
            element: <MenusList />
        },
        {
            path: '/menu/permission/list',
            element: <MenuPermissionList />
        },
        {
            path: '/menu/permission/edit/:id',
            element: <MenuPermissionEdit />
        }
    ]
};

export default MenuRoutes;
