import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CardActions,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import NotificationsIcon from "@mui/icons-material/Notifications";

import moment from "moment";
import { ClearOutlined } from "@mui/icons-material";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  "&:hover": {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
  const theme = useTheme();

  const [notificationList, setNotificationList] = useState([]);
  const [pageSize, setPageSize] = useState(5); // Number of items to fetch per page
  const [pageNumber, setPageNumber] = useState(0); // Current page number
  const [totalNotifications, setTotalNotifications] = useState(0); // Total number of notifications

  const RequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const getNotificationList = async (pageNumber, pageSize) => {
    try {
      const response = await axios.get(
        `/v1/notification/list?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "serviceToken"
            )}`,
            "Content-Type": "application/json",
            requestId: RequestId(
              Math.floor(1000 + Math.random() * 9999),
              Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
            ),
          },
        }
      );
      if (pageNumber === 0) {
        setNotificationList(response?.data?.data?.content);
      } else {
        setNotificationList((prevList) => [
          ...prevList,
          ...response?.data?.data?.content,
        ]);
      }
      setTotalNotifications(response?.data?.data?.totalElements);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to fetch data:", error);
    }
  };

  const handleClearNotification = async (id) => {
    const payload = {
      notificationIds: [id],
      clearAll: false,
    };
    try {
      await axios.post(`/v1/notification/clear/notification`, payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setNotificationList((prevList) =>
        prevList.filter((item) => item.id !== id)
      );
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to clear notification:", error);
    }
  };

  const handleClearAllNotifications = async () => {
    const payload = {
      notificationIds: notificationList.map((notification) => notification.id),
      clearAll: true,
    };
    try {
      await axios.post(`/v1/notification/clear/notification`, payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      setNotificationList([]);
      generateTimeStamp();
    } catch (error) {
      generateTimeStamp();
      console.error("Failed to clear all notifications:", error);
    }
  };

  const handleViewMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleViewLess = () => {
    setPageNumber(0);
    setNotificationList([]);
  };

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.orange.light,
    marginRight: "5px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.warning.light,
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.success.light,
    height: 28,
  };
  const senderColorMap = {};

  const getColorForSender = (senderName) => {
    if (!senderColorMap[senderName]) {
      senderColorMap[senderName] = getRandomColor();
    }
    return senderColorMap[senderName];
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    getNotificationList(pageNumber, pageSize);
  }, [pageNumber, pageSize]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" p={1}>
        {notificationList.length > 0 && (
          <Chip
            label="Clear All"
            variant="contained"
            color="secondary"
            onClick={handleClearAllNotifications}
          ></Chip>
        )}
      </Box>
      <List
        sx={{
          width: "100%",
          maxWidth: 330,
          py: 0,
          borderRadius: "10px",
          [theme.breakpoints.down("md")]: {
            maxWidth: 300,
          },
          "& .MuiListItemSecondaryAction-root": {
            top: 22,
          },
          "& .MuiDivider-root": {
            my: 0,
          },
          "& .list-container": {
            pl: 7,
          },
        }}
      >
        {notificationList && notificationList.length > 0 ? (
          notificationList.map((data) => (
            <div key={data.id}>
              <ListItemWrapper>
                <ListItem alignItems="center">
                  <IconButton>
                    <NotificationsIcon
                      sx={{
                        color: "#09296a",
                      }}
                    />
                  </IconButton>
                  <ListItemText
                    primary={
                      data?.notificationFor === "Group"
                        ? `${data?.groupName}`
                        : data?.fromUserName
                    }
                    secondary={
                      data?.notificationFor === "Group" ? (
                        <span
                          style={{
                            color: getColorForSender(data.fromUserName),
                            fontWeight: "bold",
                          }}
                        >
                          {data?.fromUserName}
                        </span>
                      ) : (
                        ""
                      )
                    }
                  />
                  <ListItemSecondaryAction>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {moment(data?.date).format("DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
                <Grid container>
                  <Grid item xs={12} sx={{ pb: 2, width: "300px" }}>
                    <Typography variant="subtitle2">{data?.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {/* <Chip
                          onClick={() => handleClearNotification(data?.id)}
                          label="Clear"
                          sx={chipErrorSX}
                        /> */}
                        <ClearOutlined
                          onClick={() => handleClearNotification(data?.id)}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Chip label="New" sx={chipWarningSX} />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </ListItemWrapper>
              <Divider />
            </div>
          ))
        ) : (
          <Typography variant="body2" sx={{ p: 2 }}>
            No notifications found
          </Typography>
        )}
      </List>

      {notificationList.length < totalNotifications && (
        <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
          <Button size="small" disableElevation onClick={handleViewMore}>
            View More
          </Button>
        </CardActions>
      )}

      {/* {pageNumber > 0 && (
        <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
          <Button size="small" disableElevation onClick={handleViewLess}>
            View Less
          </Button>
        </CardActions>
      )} */}
    </>
  );
};

export default NotificationList;
