import { useRef, memo, useState, useEffect } from "react";
import { Map, Layer, Source } from "react-map-gl";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "./layers";
import axios from "axios";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";

const MapClusters = ({ ...other }) => {
  const mapRef = useRef(null);
  const [mapdata, setMapdata] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading

  const ConvertToRequestId = useRequestIdGenerater();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const onClick = (event) => {
    const feature = event.features?.[0];
    const clusterId = feature?.properties?.cluster_id;

    // @ts-ignore
    const mapboxSource = mapRef.current?.getSource("earthquakes");

    // @ts-ignore
    mapboxSource.getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (error) {
        return;
      }

      if (feature?.geometry.type === "Point") {
        mapRef.current?.easeTo({
          center: feature?.geometry.coordinates,
          zoom: Number.isNaN(zoom) ? 3 : zoom,
          duration: 500,
        });
      }
    });
  };

  const fetchLocation = async () => {
    try {
      const response = await axios.get(`/v1/alumni/member/location/list`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "serviceToken"
          )}`,
          "Content-Type": "application/json",
          requestId: ConvertToRequestId(
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });

      const result = response?.data?.data;

      const features = result.map((location) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location?.coordinates?.x, location?.coordinates?.y],
        },
        properties: {
          alumniName: location?.alumniName,
        },
      }));

      const transformedData = {
        type: "FeatureCollection",
        features: features,
      };

      setMapdata(transformedData);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchLocation();
    generateTimeStamp();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while data is being fetched
  }

  return (
    <div style={{ height: 300 }}>
      <Map
        initialViewState={{
          latitude: 20.5937,
          longitude: 78.9629,
          zoom: 2,
        }}
        interactiveLayerIds={[clusterLayer.id || ""]}
        onClick={onClick}
        ref={mapRef}
        {...other}
      >
        <Source
          id="earthquakes"
          type="geojson"
          data={mapdata}
          cluster
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </Map>
    </div>
  );
};

export default memo(MapClusters);
